<template>
    <v-img cover min-width="100%" src="../../assets/images/home-lg.jpg"
    class="bg"
    gradient="to top right, rgba(112,112,112,.52), rgba(112,112,112,.52)"
    >
        <!--  -->
        <v-container>
            <v-row class="landing-row form pb-0 px-1" align="center" justify="center">
                <div class="mx-auto text-center white--text">
                    <h1 class="heading text-bold">Build Memories</h1>
                    <p class="brief d-none d-md-block mx-auto">Find the right trip with the right people</p>
                    <v-btn @click="$router.push({ path: '/plan-trip' })" elevation="0" color="primary" class="py-0 px-7 font-weight-regular ml-3 rounded-lg">create trip</v-btn>
                </div>
                <v-card class="search-forms my-5 pa-3" tile min-height="250" elevation="1" width="100%">
                <v-card-title class="d-flex justify-space-between align-center">
                  <h1 class="greet mb-3 primary--text text-bold">Good {{ getCurrentTime() }}!</h1>
                </v-card-title>
                <searchFormVue />
                <!-- <carsSearchForm v-show="currentForm === 'cars'" class="mt-10" />
                <staysSearchForm v-show="currentForm === 'stays'" /> -->
              </v-card>
            </v-row>
        </v-container>
    </v-img>
</template>

<script>
import searchFormVue from '../../components/stays/searchForm.vue'

export default {
  components: { searchFormVue },
  methods: {
    getCurrentTime () {
      const d = new Date()
      const time = d.getHours()
      if (time > 12 < 18) {
        return 'afternoon'
      } else if (time < 12) {
        return 'morning'
      } else {
        return 'evening'
      }
    }
  }
}
</script>
